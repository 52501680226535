@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Lato&family=Poppins&family=Roboto&family=Roboto+Mono:wght@300;500;700&display=swap');
@import url('https://www.w3schools.com/w3css/4/w3.css');
/*
=============== 
Variables
===============
*/

/* 
font-family: 'Lato', sans-serif;         ==> 
font-family: 'Poppins', sans-serif;      ==> Headings
font-family: 'Roboto', sans-serif;       ==> body
font-family: 'Roboto Mono', monospace;   ==> code 
*/

:root {
  /* sidebar */
  /* --clr-sidebar-text: hsl(130, 86%, 17%); */
  --clr-sidebar-text: hsl(76, 79%, 95%);
  --clr-sidebar-text-active: hsl(130, 72%, 37%);
  /* --clr-sidebar-bg: hsl(77, 44%, 86%); */
  --clr-sidebar-bg: hsl(128, 18%, 44%);
  --clr-sidebar-bg-active: hsl(210, 36%, 96%);
  /* main */
  /* --clr-main-bg: hsl(128, 18%, 44%); */
  /* --clr-main-bg: hsl(127, 64%, 74%); */
  --clr-main-bg: hsl(77, 44%, 86%);
  --clr-main-text: hsl(158, 96%, 10%);
  /* header & footer */
  --clr-login-footer-bg:hsl(210, 36%, 96%);
  --clr-login-bg: #E3ECCD;
  /* --clr-login-bg: #86CB92; */
  /* text */
  --clr-text-code: hsl(204, 35%, 36%);
  --clr-text-emusic: hsl(114, 81%, 21%);
  /* learning */
  --clr-learning-bg-theory: #ffc13b95;
  --clr-learning-bg-example: #ff6e4095;
  --clr-learning-bg-tiy: #1e3d59;
  /* effects */
  --transition: all 0.4s linear;
  --spacing: 0.1rem;
}

/*
=============== 
Global Styles
===============
*/

body {
  font-family: 'Roboto', sans-serif;
  background: var(--clr-main-bg);
  line-height: 1.5;
  font-size: 0.875rem;
  /* display: grid;
  grid-template-columns: auto auto-fit; */
}

.header {
  padding: 5%;
}

.footer {
  position:absolute;
  bottom:0;
  width:100%;
  height:15%;   /* Height of the footer */
}

*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  font-family: 'Raleway', sans-serif;
  color: var(--clr-main-text);
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--clr-text-emusic);
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 400;
}

h4 {
  font-size: 0.875rem;
  font-weight: 400;
}

h5 {
  font-size: 0.75rem;
  font-weight: 600;
}

p {
  margin-bottom: 1.25rem;
}

.code {
  font-family: 'Roboto Mono', sans-serif;
  color: var(--clr-text-code);
}

/* TODO: Define a color */

.emusic {
  color: var(--clr-text-emusic);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1, h2, h3, h4, h5 {
    line-height: 1;
  }
}

button {
  display: inline-block;
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.25em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #272822;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
  position: relative;
  margin: 5px;
}

button:hover {
  background: var(--clr-sidebar-bg-active);
  color: var(--clr-sidebar-text-active);
}

button:active {
  top: 0.1em;
  color: var(--clr-sidebar-text-active);
}

a:hover {
  cursor: pointer;
}

a:visited {
  color: var(--clr-sidebar-text-active);
}

.card {
  width: 25rem;
  padding: 2%;
  margin: 2%;
  margin-left: 0
}

/*
========================================================================== 
Sidebar
========================================================================== 
*/

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-sidebar-bg);
  display: grid;
  grid-template-rows: 1fr 2fr;
  row-gap: 1rem;
  transition: var(--transition);
  transform: translate(0);
  z-index: 1;
}

.sidebar-header {
  display: flex;
  justify-content: left;
  align-items: start;
  padding: 1rem 1.5rem;
}

.public-nav {
  margin-left: 3%;
}

.logo {
  justify-self: center;
  max-width: 2rem;
}

.name {
  font-family: 'Inter', sans-serif;
  font-family: 'Roboto Mono', monospace;
  color: var(--clr-sidebar-text);
  padding-left: 0.75rem;
  text-transform: lowercase;
  font-size: 1.5rem;
}

.links a {
  display: flex;
  font-size: 1.5rem;
  line-height: 1rem;
  font-family: 'Roboto Mono', monospace;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--clr-sidebar-text);
  transition: var(--transition);
  letter-spacing: var(--spacing);
  align-items: center;
}

.links a:hover {
  background: var(--clr-sidebar-bg-active);
  background-size: cover;
  color: var(--clr-sidebar-text-active);
}

.logout {
  display: flex;
  font-size: 1.5rem;
  line-height: 1rem;
  font-family: 'Roboto Mono', monospace;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--clr-sidebar-text);
  transition: var(--transition);
  letter-spacing: var(--spacing);
  align-items: center;
}

.logout:hover {
  background: var(--clr-sidebar-bg-active);
  background-size: cover;
  color: var(--clr-sidebar-text-active);
}



/* start icon colors */

.links a svg {
  font-size: 1.5rem;
  color: var(--clr-sidebar-text);
  transition: var(--transition);
}

.links a:hover svg {
  color: var(--clr-sidebar-text-active);
}

/* end icon colors */

.link-text {
  padding-left: 1rem;
}

/* start sidebar hovering */

.name, .link-text {
  display: none;
}

.sidebar:hover .link-text, .sidebar:hover .name {
  display: inline-block;
}

/* end sidebar hovering */

@media screen {
  .sidebar {
    width: auto;
  }
  .show-sidebar {
    width: auto;
  }
}

/*
========================================================================== 
Main
========================================================================== 
*/

.main {
  min-height: 100vh;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: auto;
  padding-top: 1.25rem;
  /* TODO: adjust padding-left to width of sidebar! */
  padding-left: 6.5rem;
}

/*
========================================================================== 
Login
========================================================================== 
*/

#login{
  background: var(--clr-login-bg);
  margin-bottom: 15%;
}

.login-wrapper {
  align-content: center;
  margin: 0 auto; 
  margin-top: 1%;
  border-radius: 10px;
}

.login-wrapper form, .login-wrapper #login-inputs {
  align-content: center;
  align-self: center;
}

.login-wrapper button, .login-wrapper input {
  align-content: center;
  border-color: var(--clr-login-bg);
  border-width: 3px;
  box-shadow: var(--clr-login-bg);
}

.login-wrapper input {
  border-radius: 7px;
}

.login-wrapper label {
  width: 250px;
}

html #login{
  background: var(--clr-login-bg);
}

#login-button {
  border-radius: 50px;
  border-color: var(--clr-login-bg);
  width: -webkit-fill-available;

}

.login-footer {
  padding: 1%;
  background: var(--clr-login-footer-bg);
}

/*
=============== 
Dashboard
===============
*/

#dashboard-welcome-message {
  width: -webkit-fill-available;
}

/*
=============== 
Overview Learning Modules
===============
*/

.cards-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.card-container {
  border-radius: 15px;
}

.card-container__header {
  display: flex;
  align-items: flex-end;
  align-content: space-between;
  margin-bottom: .75rem
}

.card-container__icon {
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.card-container__title {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0.25rem;
}

.card-container__title_rewards{
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0.25rem;
  text-align: center;

}

.logo-process {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 501.32px;
  top: 249.9px;
  background: #27D06B;
  border-radius: 9px;
}

.container {
  position: relative;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*
=============== 
Individual Learning Module
===============
*/

.learning {
  min-height: 100vh;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: 75vw;
  padding-left: 1rem;
}

.learning__theory {
  background-color: var(--clr-learning-bg-theory);
  border-radius: 0.5rem;
  padding: 1.75rem;
  padding-right: 3.5rem;
  margin: 1.5rem 0;
  padding-bottom: 1.25rem;
}

.learning__theory__text {
  line-height: 1.6;
  font-family: Lato;
}

.learning__theory__text--highlighted {
  background-color: #f9eabd;
  text-transform: bold;
}

.learning__example {
  background-color: var(--clr-learning-bg-example);
  border-radius: 0.5rem;
  padding: 1.75rem;
  padding-right: 3.5rem;
  margin: 1.5rem 0;
}

.learning__example__text {
  margin-top: 1.5rem;
  line-height: 1.6;
}

.learning__example__text--highlighted {
  background-color: #c4cdfb;
  text-transform: bold;
}

.learning__tiy {
  background-color: var(--clr-learning-bg-tiy);
  border-radius: 0.5rem;
  padding: 1.75rem;
  margin: 1.5rem 0;
}

.learning__tiy__text, .questionWrapperBody h3 p, .questionWrapperBody button, .learning__tiy__text h5, .drag_food_to_animals h2, .drag_food_to_animals h3, .drag_food_to_animals ul, .learning__tiy__text strong, .react-quiz-container h2 {
  line-height: 1.6;
  color: #faf9f9;
}

.col{
  text-align: center;
}

.editor {
  /* width: 100%; */
  /* height: calc(100% - 40px); */
  /* height: 100px; */
  /* z-index: -1; */
  /* isolation: isolate; */
  position: relative;
  /* margin-bottom: 2rem; */
  margin: 2rem 0;
}

.drag_food_to_animals .dnd .animal {
  padding: 4px;
  border-radius: 30px;
  margin: 12px;
  display: inline-block;
  width: 160px;
  text-align: center;
  color: white;
}

.drag_food_to_animals .highlighted .animal .dnd {
  background-color: #eee;
}

.ace_gutter {
  z-index: 0;
}

#ace-editor {
  width: 80vw;
}

#play:hover {
  background: #ccc;
  cursor: pointer;
}

#stop:hover {
  background: #ccc;
  cursor: pointer;
}

.wide-image {
  max-width: 190%;
  max-height: 100%;
}

/*
=============== 
Rewards
===============
*/